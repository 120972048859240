<template>
  <div class="section-fundraising">
    <div class="section-description">
      <span>{{ $t(descriptionTextKey) }}</span>
      <v-dialog
        class="mem-dialog"
        v-model="modal"
        :max-width="480"
        overlay-color="#000000"
        overlay-opacity="0.54"
      >
        <template v-slot:activator="{ on, attrs }">
          <span class="modal-link" v-bind="attrs" v-on="on">{{
            $t("charity_description_link")
          }}</span>
        </template>
        <div class="charity-dialog">
          <img
            src="@/assets/givestar_guide.png"
            alt="givestar guide"
            class="dialog-content"
            @click="modal = false"
          />
        </div>
      </v-dialog>
    </div>
    <div class="list-title">
      <span class="title-icon material-icons-outlined"> favorite_border </span>
      <span>{{ $t("checkout_select_a_charity") }}</span>
    </div>
    <div class="charities-list">
      <div
        class="charities-list-item"
        :class="{
          'is-selected':
            checkoutFundraisingCompany.charityId === company.charityId,
        }"
        v-for="(company, index) in companiesList"
        :key="index"
        @click="setFundraisingCompany(company)"
      >
        <img
          v-if="company.hasImage"
          :src="company.logo"
          :alt="company.name"
          class="company-image"
        />
        <div v-else>{{ $t(company.descriptionKey) }}</div>
      </div>
      <div
        class="charities-list-item"
        :class="{ 'is-selected': noFundraising }"
        @click="setFundraisingCompany()"
      >
        <div>{{ $t("charity_select_no_thanks") }}</div>
      </div>
    </div>
    <div
      class="list-title"
      :class="{ 'has-error': errors.fundraisingAmount }"
      ref="fundraisingValidationPoint"
    >
      <span class="title-icon material-icons"> monetization_on </span>
      <span>{{ $t("checkout_fundraise_target") }}</span>
    </div>
    <div class="list-subline">{{ $t("givestar_note_subline") }}</div>
    <div class="amount-list" :class="{ 'is-disabled': disableAmountList }">
      <div
        class="amount-list-item"
        :class="{
          'is-selected': checkoutFundraisingCompany.total === amount.total,
        }"
        v-for="(amount, index) in amountList"
        :key="index"
        @click="setAmounTarget(amount)"
      >
        <div>{{ amount.total | currency }}</div>
        <div v-if="amount.isPopular" class="item-subline">
          {{ $t("checkout_most_popular") }}
        </div>
      </div>
    </div>
    <i18n class="section-subline" :path="privacyPolicyTextKey" tag="span">
      <a href="https://www.spartan.com/en/about/privacy-policy" target="_blank">
        {{ $t("privacy_policy") }}</a
      >
    </i18n>
  </div>
</template>
<script>
// NOTE: This component made based on GB Checkout region requirements, might need to add chagese for other regions
import { isEmpty } from "lodash";
import { mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    noFundraising: false,
    modal: false,
  }),
  props: {
    descriptionTextKey: String,
    privacyPolicyTextKey: String,
    companiesList: Array,
    amountList: Array,
    errors: Object,
  },
  computed: {
    ...mapGetters(["checkoutRegion", "checkoutFundraisingCompany"]),
    disableAmountList() {
      return isEmpty(this.checkoutFundraisingCompany);
    },
  },
  watch: {
    "errors.fundraisingAmount": {
      handler(val) {
        if (!val) return;
        this.$refs.fundraisingValidationPoint.scrollIntoView({
          behavior: "smooth",
        });
      },
    },
  },
  methods: {
    ...mapMutations(["setCheckoutFundraisingCompany"]),
    setFundraisingCompany(company) {
      if (
        company &&
        company.charityId === this.checkoutFundraisingCompany.charityId
      )
        return this.setCheckoutFundraisingCompany({});

      if (company) {
        this.noFundraising = false;
        this.setCheckoutFundraisingCompany(company);
        return;
      }
      this.noFundraising = true;
      this.setCheckoutFundraisingCompany({});
    },
    setAmounTarget(amount) {
      if (this.disableAmountList) return;

      // console.log("Amount", amount);
      this.setCheckoutFundraisingCompany({
        ...this.checkoutFundraisingCompany,
        total: amount.total,
      });

      this.$emit("on-amount-change");
    },
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;
.charity-dialog {
  display: flex;
  background-color: #000000;
  .dialog-content {
    width: 100%;
  }
}
.section-fundraising {
  a {
    color: #4f4f4f;
  }
  .section-description {
    margin-bottom: 24px;
    .modal-link {
      margin-left: 4px;
      text-decoration: underline;
      @include cursorPointer;
      @media screen and (max-width: $mobile-view) {
        font-size: 14px;
      }
    }
  }

  .list-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    &.has-error {
      font-weight: 700;
      color: #cf1019;
    }
    .title-icon {
      font-size: 18px;
      margin-right: 12px;
    }
  }
  .list-subline {
    color: #404040;
    font-size: 12px;
    font-weight: 500;
    line-height: 133.4%; /* 16.008px */
    margin-top: 4px;
  }

  .charities-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 154px);
    gap: 8px;
    margin-top: 20px;
    margin-bottom: 24px;
    @media screen and (max-width: $mobile-view) {
      grid-template-columns: 1fr 1fr;
      margin-top: 30px;
      margin-bottom: 26px;
    }
    .charities-list-item {
      position: relative;
      height: 100px;
      @media screen and (max-width: $mobile-view) {
        height: 80px;
      }
      border-radius: 12px;
      border: 1px solid #d8d8d8;
      padding-left: 24px;
      padding-right: 24px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;

      font-size: 12px;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;

      @include cursorPointer;
      &.is-selected {
        border-color: #cf1019;
      }
      .company-image {
        transform: scale(0.2);
        position: absolute;
      }
    }
  }
  .amount-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 125px);
    gap: 8px;
    margin-top: 20px;
    margin-bottom: 24px;
    @media screen and (max-width: $mobile-view) {
      grid-template-columns: 1fr 1fr;
      margin-top: 30px;
      margin-bottom: 26px;
    }
    &.is-disabled {
      pointer-events: none;
      .amount-list-item {
        opacity: 0.2;
        background-color: #e0e0e0;
      }
    }
    .amount-list-item {
      position: relative;
      height: 100px;
      @media screen and (max-width: $mobile-view) {
        height: 80px;
      }
      border-radius: 12px;
      border: 1px solid #d8d8d8;
      padding-left: 24px;
      padding-right: 24px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      font-size: 12px;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;

      @include cursorPointer;
      &.is-selected {
        border-color: #cf1019;
      }

      .item-subline {
        margin-top: 4px;
        color: #9d9d9d;
        text-align: center;
        font-size: 8px;
        font-weight: 600;
        letter-spacing: 0.16px;
        text-transform: uppercase;
      }
    }
  }
  .section-subline {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>